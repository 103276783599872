/* General styling */
.contact-support {
    max-width: 500px; /* Limit the max width */
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-support h2 {
    text-align: center;
  }
  
  .contact-support form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-support div {
    margin-bottom: 15px;
  }
  
  .contact-support label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-support input,
  .contact-support textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }
  
  .contact-support button {
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contact-support button:hover {
    background-color: #45a049;
  }
  
  .contact-support p {
    color: #e74c3c;
    text-align: center;
  }
  
  .contact-support p.success {
    color: #2ecc71;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-support {
      padding: 15px;
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .contact-support h2 {
      font-size: 22px; /* Slightly smaller heading on mobile */
    }
  
    .contact-support input,
    .contact-support textarea {
      font-size: 16px; /* Make text a bit larger for better readability */
    }
  
    .contact-support button {
      padding: 14px;
      font-size: 18px; /* Larger button for mobile */
    }
  }
  
  @media (max-width: 480px) {
    .contact-support {
      padding: 10px;
    }
  
    .contact-support h2 {
      font-size: 20px; /* Even smaller heading on very small screens */
    }
  
    .contact-support input,
    .contact-support textarea {
      font-size: 14px;
    }
  
    .contact-support button {
      padding: 12px;
      font-size: 16px;
    }
  }


  .countrycode{
    background: #ccc;
    position: absolute;
    padding: 19px;
    font-size: 16px;
    font-weight: bold;
    border-right: 2px solid #ccc;
    border-radius: 7px 0px 0px 8px;
  }

  .container{
    background: #fff;
    padding-top: 20px;
  }

  .inputphone{
    padding: 40px 40px;
  }
  